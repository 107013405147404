import React, { useState } from "react";
import PropTypes from "prop-types";

const Context = React.createContext();

function ContextProvider(props) {
  const [state, setState] = useState({
    showDescription: false,
    input: "",
    number: "",
    selected: {
      brandSelected: "",
      modelSelected: "",
    },
    showLogo: false,
    searchOpacity: false,
    selectOpacity: false,
  });

  return (
    <Context.Provider value={{ state, setState }}>
      {props.children}
    </Context.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.any,
};

export { ContextProvider, Context };
