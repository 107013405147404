import React, { useContext } from 'react';
import { Context } from './Context';
import info from '../images/info.png';
import PropTypes from 'prop-types';

function TableHeader({ column }) {
  const { state, setState } = useContext(Context);

  if (column.id === 'col9') {
    return (
      <th {...column.getHeaderProps()}>
        {column.render('Header')}
        <img
          src={info}
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              showDescription: !state.showDescription,
            }))
          }
        />
      </th>
    );
  }

  return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
}

TableHeader.propTypes = {
  children: PropTypes.node,
};

export default TableHeader;
