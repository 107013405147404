import React from "react";
import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Page() {
  return (
    <>
      <Container>
        <Header></Header>
        <Outlet />
      </Container>
      <Footer></Footer>
    </>
  );
}

export default Page;
