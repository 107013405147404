import React, { useContext } from "react";
import { default as ReactSelect } from "react-select";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import { Context } from "../components/Context";

function Select({ options, placeholder, value, onChange, creatable }) {
  const {
    state: { selectOpacity },
  } = useContext(Context);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      opacity: 0.3,
    }),
  };

  const properties = {
    className: "option__list",
    classNamePrefix: "react-select",
    placeholder: placeholder,
    noOptionsMessage: () => "Keine Optionen",
    options,
    value,
    onChange,
    isClearable: true,
    styles: selectOpacity && customStyles,
  };

  return creatable ? (
    <CreatableSelect
      {...properties}
      createOptionPosition="first"
      isValidNewOption={() => true}
      formatCreateLabel={(userInput) => `Suchen nach ${userInput || "..."}`}
    />
  ) : (
    <ReactSelect {...properties} />
  );
}

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  creatable: PropTypes.bool,
};

Select.defaultProps = {
  creatable: false,
};

export default Select;
