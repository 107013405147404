import React, { useContext, useEffect } from "react";
import { Context } from "./Context";
import Table from "./Table";
import Search from "./Search";

function Home() {
  const { setState } = useContext(Context);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      showLogo: true,
    }));
  }, []);
  return (
    <>
      <Search></Search>
      <Table></Table>
    </>
  );
}

export default Home;
