import React, { useContext } from "react";
import { Context } from "./Context";

function Number() {
  const {
    state: { input, searchOpacity },
    setState,
  } = useContext(Context);

  const resetInput = () => {
    setState((prevState) => ({
      ...prevState,
      input: "",
      number: "",
      selectOpacity: false,
    }));
  };

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      input: e.target.validity.valid ? e.target.value : prevState.input,
      selectOpacity: true,
      searchOpacity: false,
    }));
  };

  return (
    <div className='eurotax__number'>
      <label>
        Nationalcode
        <input
          type='text'
          pattern='[0-9]{1,9}'
          name='input'
          value={input}
          placeholder='Nationalcode eingeben'
          onChange={handleChange}
          style={{ opacity: searchOpacity && "0.3" }}
        />
        <span onClick={resetInput} style={{ opacity: searchOpacity && "0.3" }}>
          {input && "x"}
        </span>
      </label>
      <button
        className='button'
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            number: input,
            selected: {
              brandSelected: "",
              modelSelected: "",
            },
          }))
        }
        style={{ opacity: searchOpacity && "0.3" }}
      >
        suchen
      </button>
    </div>
  );
}

export default Number;
