import React, { useContext } from "react";
import Number from "./Number";
import Brand from "./Brand";
import Model from "./Model";
import { Context } from "./Context";

function Search() {
  const {
    state: {
      selected: { brandSelected },
    },
    setState,
  } = useContext(Context);

  return (
    <div className="search">
      <Number></Number>
      <div className="search__banner">
        <span className="search__banner__label">Automarke</span>
        <Brand></Brand>
        {brandSelected && <Model></Model>}
        <button
          className="search__banner__button"
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              selected: {
                brandSelected: "",
                modelSelected: "",
              },
              searchOpacity: false,
            }))
          }
        >
          zurücksetzen
        </button>
      </div>
    </div>
  );
}

export default Search;
