import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils";
import Home from "./Home";

function ProtectedRoute() {
  return isAuthenticated() ? <Home /> : <Navigate to="/login" />;
}

export default ProtectedRoute;
