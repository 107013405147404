import React, { useContext, useState, useEffect } from "react";
import Select from "./Select";
import { Context } from "./Context";
import base from "../api/base";

function Model() {
  const {
    state: {
      selected: { modelSelected, brandSelected },
    },
    setState,
  } = useContext(Context);
  const [models, setModels] = useState([]);

  useEffect(() => {
    setState((prevState) => {
      prevState.selected.modelSelected = "";

      return { ...prevState };
    });

    setModels([]);

    base("data")
      .select({
        view: "Grid view",
        fields: ["carModel"],
        filterByFormula: "({carBrand} = '" + brandSelected.value + "')",
      })
      .eachPage((records, fetchNextPage) => {
        setModels((prevState) => {
          return [
            ...new Set([
              ...prevState,
              ...records.map((record) => record.fields.carModel),
            ]),
          ];
        });

        fetchNextPage();
      });
  }, [brandSelected]);

  const options_sorted = models.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  const options = options_sorted.map((model) => {
    return {
      value: model,
      label: model,
    };
  });

  return (
    <Select
      options={options}
      placeholder="Modell"
      value={modelSelected}
      creatable
      onChange={(value) => {
        setState((prevState) => {
          let model = value || "";

          return {
            ...prevState,
            selected: {
              ...prevState.selected,
              modelSelected: model,
            },
          };
        });
      }}
    ></Select>
  );
}

export default Model;
