import React, { useContext, useState, useEffect } from "react";
import Select from "./Select";
import { Context } from "./Context";
import base from "../api/base";

function Brand() {
  const {
    state: {
      selected: { brandSelected },
    },
    setState,
  } = useContext(Context);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    base("brand")
      .select({ view: "Grid view" })
      .eachPage((records) => {
        setBrands(records.map((record) => record.fields.Name));
      });
  }, []);

  const options = brands.map((brand) => {
    return {
      value: brand,
      label: brand,
    };
  });

  return (
    <Select
      options={options}
      placeholder="Automarke wählen"
      value={brandSelected}
      onChange={(value) =>
        setState((prevState) => {
          let brand;

          brand = value == null ? "" : value;

          return {
            ...prevState,
            input: "",
            number: "",
            selected: {
              ...prevState.selected,
              brandSelected: brand,
            },
            searchOpacity: true,
            selectOpacity: false,
          };
        })
      }
    ></Select>
  );
}

export default Brand;
