import React from "react";
import Container from "react-bootstrap/Container";
import logo from "../images/sens-logo.png";
import arrow from "../images/arrow.png";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__details">
          <p className="footer__paragraph">
            Stimmen die Angaben nicht oder haben Sie Informationen zu
            Fahrzeugen, melden Sie sich bei uns.
          </p>
          <p className="footer__details__copyrights">
            Datenbank bereitgestell von
            <a
              href="https://www.erecycling.ch/"
              target="blank"
              className="button__link"
            >
              <img src={logo} />
            </a>
          </p>
        </div>
        <div className="footer__details">
          <button className="button footer__details__button">
            <a
              href="mailto: info@eRecycling.ch"
              className="button__link footer__details__button__link"
            >
              <img src={arrow} />
              melden
            </a>
          </button>
          <a
            href="https://www.erecycling.ch/landing-pages/datenschutz.html"
            target="blank"
            className="button__link footer__details__button__link footer__details--padding--font-size"
          >
            Datenschutz
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
