import React, { useContext } from "react";
import arrow from "../images/arrow.png";
import { Context } from "./Context";

function Header() {
  const {
    state: { showLogo },
    setState,
  } = useContext(Context);

  return (
    <header className="header">
      <h1
        className="header__heading"
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            selected: {
              brandSelected: "",
              modelSelected: "",
            },
          }))
        }
      >
        EV Datenbank
      </h1>
      {showLogo && (
        <div className="header__buttons">
          <button className="button">
            <a
              href="https://portal.sens.ch/webportal.aspx"
              target="blank"
              className="button__link"
            >
              <img src={arrow} />
              zur Deklaration
            </a>
          </button>
          <button className="button">
            <a
              href="http://api.ev-datenbank.ch/api-docs"
              target="blank"
              className="button__link"
            >
              <img src={arrow} />
              via API zugreifen
            </a>
          </button>
        </div>
      )}
    </header>
  );
}

export default Header;
