import React, { useContext, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Context } from './Context';
import Description from './Description';
import TableData from './TableData';
import TableHeader from './TableHeader';
import base from '../api/base';

let fnp = null;

function Table() {
  const {
    state: {
      showDescription,
      number,
      selected: { brandSelected, modelSelected },
    },
  } = useContext(Context);

  const [records, setRecords] = useState([]);

  useEffect(() => {
    setRecords([]);

    let filterString = null;

    if (brandSelected.value && modelSelected.value) {
      filterString =
        "AND({carBrand} = '" +
        brandSelected.value +
        "', " +
        `FIND((LEFT("${modelSelected.value.toLowerCase()}", ${
          modelSelected.value.length
        })), LOWER(carModel)) > 0` +
        ')';
    } else if (brandSelected.value) {
      filterString = "({carBrand} = '" + brandSelected.value + "')";
    } else if (number) {
      filterString = `(FIND("${number}", nationalcode) > 0)`;
    }

    base('data')
      .select({
        view: 'Grid view',
        pageSize: 10,
        filterByFormula: filterString || '',
      })
      .eachPage((records, fetchNextPage) => {
        setRecords((prevState) => [...prevState, ...records]);

        if (records.length === 0 && number !== '') {
          let time = new Date().toISOString().replace('T', ' ').replace('Z', '');

          base('Nationalcode').create([
            {
              fields: { Number: number, Time: time },
            },
          ]);
        }

        fnp = fetchNextPage;
      });
  }, [brandSelected, modelSelected, number]);

  const data = React.useMemo(() =>
    records.map((brand) => {
      const {
        carBrand,
        carModel,
        name,
        leistung,
        Kapazitaet_in_kWh,
        batterieGewicht,
        vRB_Tarifkategorie,
        vRB_CHF,
        Status,
        nationalcode,
      } = brand.fields;

      return {
        key: nationalcode,
        col1: carBrand,
        col2: carModel,
        col3: name,
        col4: leistung,
        col5: Kapazitaet_in_kWh,
        col6: batterieGewicht,
        col7: vRB_Tarifkategorie,
        col8: vRB_CHF,
        col9: Status,
        col10: nationalcode,
      };
    })
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Hersteller',
        accessor: 'col1',
      },
      {
        Header: 'Modell',
        accessor: 'col2',
      },
      {
        Header: 'Modellstart',
        accessor: 'col3',
      },
      {
        Header: `Systemleistung`,
        accessor: 'col4',
      },
      {
        Header: `Kapazität in kWh`,
        accessor: 'col5',
      },
      {
        Header: 'Batteriegewicht',
        accessor: 'col6',
      },
      {
        Header: 'SENS Kategorie',
        accessor: 'col7',
      },
      {
        Header: 'vRB pro Fahrzeug',
        accessor: 'col8',
      },
      {
        Header: 'Status',
        accessor: 'col9',
      },
      {
        Header: 'Nationalcode',
        accessor: 'col10',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className='board__wrapper'>
        <table {...getTableProps()} className='board'>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={`header-group-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return <TableHeader key={column.id} column={column} />;
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <TableData key={cell.id} row={row} cell={cell} />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {records.length >= 10 && (
          <button className='button' onClick={() => fnp()}>
            <span className='button__link'>mehr laden</span>
          </button>
        )}
      </div>
      {showDescription && <Description></Description>}
    </>
  );
}

export default Table;
