/* eslint-disable */
import React, { useState } from "react";

function NationalCode({ cell }) {
  const [toggle, setToggle] = useState(false);

  let nationalCode = cell.row.values.col10.split(", ");

  return (
    <td {...cell.getCellProps()}>
      {nationalCode.length > 1 && !toggle ? (
        <>
          <span>{nationalCode[0]}, </span>
          <span onClick={() => setToggle(true)} style={{ cursor: "pointer" }}>
            ...
          </span>
        </>
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
}

export default NationalCode;
