import React from "react";
import { Navigate } from "react-router-dom";
import Login from "./Login";
import { isAuthenticated } from "../utils";

function Redirect() {
  return isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />;
}

export default Redirect;
