import React, { useContext } from 'react';
import NationalCode from './NationalCode';
import check from '../images/check.png';
import prognose from '../images/prognose.png';
import clock from '../images/clock.png';
import keineAngaben from '../images/keine-angaben.png';
import durchschnitt from '../images/durchschnitt.png';
import { Context } from './Context';
import PropTypes from 'prop-types';

function TableData({ row, cell }) {
  const { state, setState } = useContext(Context);

  const { col5, col8, col9, col10 } = cell.row.values;

  const getCapacity = () =>
    col5 !== undefined ? <td {...cell.getCellProps()}>{cell.render('Cell')} kWh</td> : <td></td>;

  const getCategory = () => (
    <td {...cell.getCellProps()}>
      <div className='category'>
        <span>{cell.render('Cell')}</span>
      </div>
    </td>
  );

  const getVRB = () =>
    col8 !== undefined ? <td {...cell.getCellProps()}>CHF {cell.render('Cell')}</td> : <td></td>;

  const getStatus = () => {
    let backgroundColor = '';
    let url = '';
    let status = '';

    switch (col9) {
      case 'Match':
      case 'Von SENS verifiziert':
        backgroundColor = '#B2C853';
        url = check;
        break;
      case 'Ambiguous Match':
      case 'Prüfung ausstehend':
        backgroundColor = '#8F8F91';
        url = clock;
        break;
      case 'SENS-Durchschnittberechnung':
        backgroundColor = '#FFA900';
        url = durchschnitt;
        status = 'SENS-Durchschnitt';
        break;
      case undefined:
      case 'No Match':
      case 'Aktuell unbekannt':
        backgroundColor = '#D22D76';
        url = keineAngaben;
        status = 'Aktuell unbekannt';
        break;
      default:
        backgroundColor = '#7B61FF';
        url = prognose;
        break;
    }

    return (
      <td {...cell.getCellProps()}>
        <div
          className='status'
          style={{ backgroundColor }}
          onClick={() =>
            setState((prevState) => ({ ...prevState, showDescription: !state.showDescription }))
          }
        >
          <img src={url} alt='status icon' />
          <span>{status ? status : cell.render('Cell')}</span>
        </div>
      </td>
    );
  };

  const getNationalCode = () => {
    if (col10 == undefined) {
      const { col1, col2, col3 } = row.values;

      return (
        <td {...cell.getCellProps()}>
          <a
            className='nationalCode'
            href={`mailto:info@erecycling.ch?subject=Nationalcode Meldung Fahrzeug ${col1} ${col2} ${col3}`}
          >
            Nationalcode melden
          </a>
        </td>
      );
    }

    return <NationalCode cell={cell} />;
  };

  switch (cell.column.id) {
    case 'col5':
      return getCapacity();
    case 'col7':
      return getCategory();
    case 'col8':
      return getVRB();
    case 'col9':
      return getStatus();
    case 'col10':
      return getNationalCode();
    default:
      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  }
}

TableData.propTypes = {
  children: PropTypes.node,
};

export default TableData;
