import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../images/arrow.png";
import base from "../api/base";

function Login() {
  const navigate = useNavigate();

  const [kunden, setKunden] = useState([]);
  const [kundenNumber, setKundenNumber] = useState("");
  const [isKundenNumberValid, setIsKundenNumberValid] = useState(true);

  useEffect(() => {
    base("kunden")
      .select({
        view: "Grid view",
      })
      .eachPage((records, fetchNextPage) => {
        setKunden(records.map((record) => record.get("Kundennummer")));

        fetchNextPage();
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const kundenNumberValue = kunden.find((number) => number == kundenNumber);

    setIsKundenNumberValid(kundenNumberValue != undefined);

    if (kundenNumberValue != undefined) {
      let now = new Date();

      now.setDate(now.getDate() + 30);

      const item = Math.floor(now.getTime() / 1000);

      localStorage.setItem("expiresAt", item);

      navigate("/dashboard");
    }
  };

  return (
    <div className="login">
      <p className="login__entry-paragraph">
        Die EV Datenbank umfasst mehr als 2’200 Elektrofahrzeuge mit
        Batteriekapazität und der dazugehörigen SENS Tarifkategorie für die
        Abrechnung der vRB.
      </p>
      <p className="login__form-paragraph">
        Bitte melden Sie sich mit Ihrer SENS Kundenummer an um Zugriff auf die
        Datenbank zu erhalten:
      </p>
      <form className="login__form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="SENS Kundennummer"
          name="kundenNumber"
          value={kundenNumber}
          onChange={(e) => setKundenNumber((e.target.name = e.target.value))}
          style={{
            borderColor: isKundenNumberValid ? "initial" : "#D22D76",
            color: isKundenNumberValid ? "initial" : "#D22D76",
          }}
        />
        <button
          className="button button__link login__form__button"
          type="submit"
        >
          <img src={arrow} />
          anmelden*
        </button>
        {!isKundenNumberValid && (
          <p className="login__form__error-message">
            [ ! ] Die Kundennumber konnte im System leider nicht gefunden
            werden. Versuchen Sie es bitte nochmals oder kontaktieren Sie uns.
          </p>
        )}
        <p className="login__form__information">
          * mit der Anmeldung akzeptieren Sie, dass mit Hilfe von Google
          Analytics Web-Statistiken anonym erhoben werden.
        </p>
      </form>
      <p className="login__register-paragraph">
        Sie haben noch keine SENS Kundennummer? Melden Sie sich bei uns:
      </p>
      <button className="button">
        <a
          href="mailto: info@eRecycling.ch"
          className="button__link login__register-link"
        >
          <img src={arrow} />
          melden
        </a>
      </button>
    </div>
  );
}

export default Login;
