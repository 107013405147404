import React, { useContext } from "react";
import close from "../images/close.png";
import check from "../images/check.png";
import clock from "../images/clock.png";
import keineAngaben from "../images/keine-angaben.png";
import durchschnitt from "../images/durchschnitt.png";
import prognose from "../images/prognose.png";
import { Context } from "./Context";

function Description() {
  const {
    state: { showDescription },
    setState,
  } = useContext(Context);

  return (
    <div className="description--overlay">
      <div className="description">
        <div className="description__close">
          <img
            src={close}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showDescription: !showDescription,
              }))
            }
          />
        </div>
        <div className="description__details">
          <h3 className="description__details__heading">
            Erklärungen zu den unterschiedlichen Stati
          </h3>
          <div className="description__details__statuses">
            <div className="description__details__statuses__buttons">
              <div className="description__details__statuses__buttons__button">
                <img src={check} />
                <span>Von SENS verifiziert</span>
              </div>
              <div className="description__details__statuses__buttons__button">
                <img src={prognose} />
                <span>SENS-Hochrechnung</span>
              </div>
              <div className="description__details__statuses__buttons__button">
                <img src={clock} />
                <span>Prüfung ausstehend</span>
              </div>
              <div className="description__details__statuses__buttons__button">
                <img src={durchschnitt} />
                <span>SENS-Durchschnitt</span>
              </div>
              <div className="description__details__statuses__buttons__button">
                <img src={keineAngaben} />
                <span>Aktuell unbekannt</span>
              </div>
            </div>
            <div className="description__details__statuses__explained">
              <p>
                SENS hat die Angaben beim Hersteller eingeholt und manuell
                geprüft.
              </p>
              <p>
                SENS hat auf Basis der Angaben der Batterieleistung eine
                Hochrechnung des Batteriegewichts vorgenommen.
              </p>
              <p>
                Angaben wurden uns übermittelt, welche zur manuellen Prüfung
                bereitstehen.
              </p>
              <p>Durchschnittsgewicht aus der Fahrzeugkategorie.</p>
              <p>Aktuell liegen zu diesem Fahrzeug keine Angaben vor.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
