function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const isAuthenticated = () => {
  let isAuthenticated = false;

  let expiresAt = localStorage.getItem("expiresAt");

  const expiryDate = new Date(expiresAt ? expiresAt * 1000 : undefined);

  if (isValidDate(expiryDate)) {
    if (expiryDate <= new Date()) {
      localStorage.removeItem("expiresAt");
    } else {
      isAuthenticated = true;
    }
  }

  return isAuthenticated;
};
