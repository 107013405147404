import React from "react";
import { Routes, Route } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import Page from "./components/Page";
import ProtectedRoute from "./components/ProtectedRoute";
import Redirect from "./components/Redirect";

function App() {
  return (
    <>
      <ContextProvider>
        <Routes>
          <Route path="/" element={<Page />}>
            <Route path="/" element={<ProtectedRoute />} />
            <Route path="dashboard" element={<ProtectedRoute />} />
            <Route path="login" element={<Redirect />} />
          </Route>
        </Routes>
      </ContextProvider>
    </>
  );
}

export default App;
